<template lang="">
  <div>
    <tree-select
      v-model="selected"
      :disabled="disableProp"
      :options="dataBusinessSectorUpdated"
      :normalizer="normalizer"
      :load-options="loadOptions"
      :valueFormat="valueFormat"
      :placeholder="'Lựa chọn ngành nghề kinh doanh'"
      :noChildrenText="'không có ngành nghề kinh doanh con'"
      @input="selectChange"
      @select="checkNameText"
    >
      <div
        v-if="allowAddNew"
        slot="before-list"
      >
        <div
          class="header-template"
          @click="showAddModal"
        >
          <feather-icon icon="PlusCircleIcon" />
          <span class="btn-text-select">{{ $t('common.add') }}</span>
        </div>
        <div class="border-item-select"></div>
      </div>
    </tree-select>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import TreeSelect, { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// const simulateAsyncOperation = fn => {
//   setTimeout(fn, 1000)
// }
export default {
  components: {
    TreeSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },
    allowAddNew: {
      type: Boolean,
      default: false,
    },
    valueFormat: {
      type: String,
      default: 'id',
    },
    parentId: {
      type: String,
      default: '',
    },
    idMinus: {
      type: String,
      default: '',
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
    isMinus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: '',
      normalizer(node) {
        let code = ''
        // if (node.level1) {
        //   code = node.level1
        // }
        // if (!node.level1) {
        //   code = node.level2
        // }
        // if (!node.level1 && !node.level2) {
        //   code = node.level3
        // }
        for (let i = 1; i <= 5; i++) {
          if (node[`level${i}`]) {
            code = node[`level${i}`]
          }
        }
        return {
          id: node.id,
          label: `${code} - ${node.name}`,
          children: node.childrens,
        }
      },
    }
  },
  computed: {
    ...mapGetters('businessSector', ['dataBusinessSectorUpdated', 'dataBusinessSectorChildren']),
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== this.selected) {
        this.fetchData()
        this.selected = newValue
      }
    },
  },
  mounted() {
    this.fetchData()
  },
  // created() {
  //   this.fetchData()
  //   // this.doFetchOrganizationChildren(this.id)
  // },
  methods: {
    ...mapActions('businessSector', ['doFetchBusinessSectorUpdated', 'doFetchBusinessSectorChildren']),
    async loadOptions({ action, parentNode, callback }) {
      // Typically, do the AJAX stuff here.
      // Once the server has responded,
      // assign children options to the parent node & call the callback.
      if (action === LOAD_CHILDREN_OPTIONS) {
        const model = {
          id: parentNode.id,
          idMinus: this.idMinus,
          isMinus: this.isMinus,
        }
        await this.doFetchBusinessSectorChildren(model)
        if (this.dataBusinessSectorChildren.length > 0) {
          this.dataBusinessSectorChildren.forEach(element => {
            element.childrens = null
          })
        } else { this.dataBusinessSectorChildren = undefined }
        parentNode.childrens = this.dataBusinessSectorChildren
      }
    },
    async fetchData() {
      const model = {
        id: this.value || null,
        idMinus: this.idMinus,
        isMinus: this.isMinus,
      }
      await this.doFetchBusinessSectorUpdated(model)
      this.dataBusinessSectorUpdated.forEach(element => {
        element.childrens = null
      })
      this.selected = this.value === '' || this.value === '0' ? null : this.value
    },
    checkNameText(value) {
      this.$emit('checkNameText', value)
    },
    selectChange(value) {
      this.$emit('input', value)
    },
    showAddModal() {
      this.$emit('addNewItem')
    },
  },
}
</script>
<style lang="scss" scoped>
.header-template {
  margin: 0px 10px;
  text-align: left;
  color: #227ff4;
  svg {
    fill: transparent !important;
  }
}

.btn-text-select {
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
}
.border-item-select {
  border-bottom: 1px;
  border-color: #ebe9f1;
  width: 100%;
}
</style>

<style lang="scss">
.is-invalid {
  .vue-treeselect__control {
    border-color: #ea5455;
    background-repeat: no-repeat;
    background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
  }
}
.vue-treeselect__control {
  border: 1px solid #a6a8b0;
}
</style>
