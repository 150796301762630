<template>
  <div>
    <b-modal
      :id="id"
      size="xl"
      cancel-title="Hủy bỏ"
      cancel-variant="outline-primary"
      ok-title="Tải xuống"
      centered
      @ok="downloadFile"
    >
      <embed
        :src="pdfsrc"
        height="100%"
        width="100%"
      />
    </b-modal>
  </div>
</template>
<script>
import pdf from 'vue-pdf'

export default {
  components: {
    pdf,
  },
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
    pdfsrc: {
      type: String,
      default: '',
    },
  },
  methods: {
    // Tải file có trong giấy phép lao dộng
    downloadFile() {
      this.$emit('downloadFile')
    },
  },
}
</script>

<style lang="scss">
#confirm-see-PDF-embed {
  .modal-dialog {
    .modal-body {
      height: 800px;
    }
  }
}
</style>
