<template>
  <b-modal
    :id="id"
    size="sm"
    title="Trả lại"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleReject"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group>
        <label for="InputHelp">Lý do <span class="required">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          name="reasonReject"
          rules="required"
          :custom-messages="{required: `Lý do là bắt buộc`,}"
        >
          <b-form-textarea
            id="InputHelp"
            v-model="dataInput.reasonReject"
            rows="3"
            no-resize
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import {
  BFormTextarea, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        reasonReject: '',
      },
    }
  },
  methods: {
    handleReject(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleReject', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        reasonReject: '',
      }
    },
  },
}
</script>

<style>

</style>
