<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="tab-assigned"
      typeCss="active-full"
      @setActive="setActive"
    />

    <div>
      <status-report-assigned
        v-if="isRendered('tab-assigned')"
        class="tab-pane"
        :class="{'active':activeTab==='tab-assigned'}"
      />
      <status-report-un-assigned
        v-if="isRendered('tab-unassigned')"
        class="tab-pane"
        :class="{'active':activeTab==='tab-unassigned'}"
      />
      <Enterprises
        v-if="isRendered('enterprise')"
        class="tab-pane"
        :class="{'active':activeTab==='enterprise'}"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BFormGroup, BForm,
} from 'bootstrap-vue'

import MyTabs from '@/components/tabs/MyTabs.vue'
import StatusReportAssigned from './tab-item-status/StatusReportAssigned.vue'
import StatusReportUnAssigned from './tab-item-status/StatusReportUnAssigned.vue'
import Enterprises from './tab-item-status/EnterprisesSubmitReport.vue'

export default {
  components: {
    MyTabs,
    StatusReportAssigned,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    StatusReportUnAssigned,
    Enterprises,
  },
  data() {
    return {
      roleUser: JSON.parse(localStorage.getItem('userData')).roleIdentity,
      activeTab: null,
      listTabs: [
        {
          key: 'tab-assigned',
          title: 'Đã nộp',
          icon: 'CheckSquareIcon',
          isRendered: false,
        },
        {
          key: 'tab-unassigned',
          title: 'Chưa nộp',
          icon: 'XSquareIcon',
          isDisabled: false,
        },
        // {
        //   key: 'enterprise',
        //   title: 'Công bố danh sách doanh nghiệp nộp báo cáo định kỳ',
        //   icon: 'ListIcon',
        //   isDisabled: false,
        // },
      ],
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  created() {
    if (this.activeTab === null) {
      this.activeTab = 'tab-assigned'
      this.listTabs[0].isRendered = true
    }
    if (this.roleUser !== 'EducationDepartment') {
      this.listTabs.push({
        key: 'enterprise',
        title: 'Công bố danh sách doanh nghiệp nộp báo cáo định kỳ',
        icon: 'ListIcon',
        isDisabled: false,
      })
    }
  },
  methods: {
    // load từng tab khi click vào tab
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },
  },
}
</script>

<style lang="scss">
  .text-filter {
    color: #181F28;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
</style>
