<template>
  <div id="unAssignedItem">
    <div class="page-container mb-2">
      <span class="text-filter">Bộ Lọc</span>
      <b-row class="mt-1">
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Năm</label>
            <v-select
              :reduce="title => title"
              :options="dataYear || []"
              :placeholder="'Năm'"
              @input="filterYear"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Doanh nghiệp</label>
            <v-select
              :reduce="title => title.id"
              label="name"
              :options="dataAllBusiness || []"
              :placeholder="'Doanh nghiệp'"
              @input="filterBussiness"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Tình trạng</label>
            <v-select
              :reduce="title => title.en"
              label="vi"
              :options="expireReport || []"
              :placeholder="'Tình trạng'"
              @input="filterExpire"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Trạng Thái</label>
            <v-select
              :reduce="title => title.en"
              label="vi"
              :options="statusReport || []"
              :placeholder="'Tình trạng'"
              @input="filterStatus"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Loại hình doanh nghiệp</label>
            <v-select
              :reduce="title => title.id"
              label="name"
              :options="dataTypeBussiness || []"
              :placeholder="'Loại hình doanh nghiệp'"
              @input="filterTypeBussiness"
            >
              <template #option="{name, code}">
                <span
                  class="ml-50"
                >{{ code }} - {{ name }}</span>
              </template>
              <template
                #selected-option="{name, code}"
                class="scroll-select-option"
              >
                <span
                  class="ml-50"
                >{{ code }} - {{ name }}</span>
              </template>
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div
      class="page-container-table"
    >
      <div class="d-flex justify-content-between button-header ml-1 mr-1 mb-1 ">
        <div
          id="btn-all-header"
          class="d-flex"
        >
          <button-component
            v-b-tooltip.hover.top="'Gửi thông báo nhắc nhở'"
            class="button-icon mr-1"
            content-btn=""
            variant="primary"
            icon-btn="SendIcon"
            :disabled="!showBtnMultiDelete"
            @click="SendNotifications"
          />
          <button-component
            v-b-tooltip.hover.top="'Gửi thông báo đến tất cả doanh nghiệp'"
            class="button-icon mr-1"
            content-btn=""
            variant="light"
            icon-btn="UsersIcon"
            @click="SendNotificationsAll"
          />
          <b-button
            v-b-tooltip.hover.top="'Xuất Excel'"
            variant="outline-primary"
            class="mr-1 button-icon-custom"
            @click="downloadExportFile"
          >
            <span class="icon-excel pr-25">
            </span>
          </b-button>

        </div>
        <search
          class="search-input"
          @change="search"
        />
      </div>

      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataTable || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã loại báo cáo -->
          <span
            v-if="props.column.field === 'bussinessCode'"
            class="text-nowrap edit-report"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ $t(props.row.bussinessCode) }}</span>
            </b-badge>
          </span>

          <!-- Column:Hạn nộp báo cáo -->
          <span v-else-if="props.column.field === 'dateExpired'">
            <span class="text-nowrap">{{ props.row.dateExpired | formatDateToDDMM }}</span>
          </span>

          <!-- Column:Trạng thái -->
          <span v-else-if="props.column.field === 'submitReportStatusString'">
            <b-badge
              pill
              :variant="statusVariant(props.row.submitReportStatus)"
              class="border-status"
            >
              <span>{{ props.row.submitReportStatusString }}</span>

            </b-badge>
          </span>

          <!-- Column:Chức năng -->
          <span
            v-else-if="props.column.field === 'actionFunction'"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Gửi thông báo nhắc nhở'"
              icon="SendIcon"
              size="18"
              @click="openModalSend(props.row.bussinessId)"
            />
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <modal-send-notifi
        :id="modalSendNotifi"
        :years="dataYear"
        @handleSendMessage="handleSendMessage"
      />
    </div>
  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge, BRow, BCol, BFormGroup, VBTooltip, BButton,
} from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import DataFilter from '../../constants/DataFilter'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BBadge,
    ModalSendNotifi: () => import('../components/ModalSendNotifi.vue'),
    BRow,
    BCol,
    BFormGroup,
    VSelect: () => import('vue-select'),
    BButton,
    ButtonComponent: () => import('@/components/button/ButtonComponent.vue'),
    FeatherIcon: () => import('@/@core/components/feather-icon/FeatherIcon.vue'),
    Search: () => import('@/components/search/Search.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      dataTable: [],
      columns: [
        {
          label: 'MÃ DOANH NGHIỆP',
          field: 'bussinessCode',
          sortable: false,
        },
        {
          label: 'TÊN DOANH NGHIỆP',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'LOẠI BÁO CÁO',
          field: 'name',
          sortable: false,
        },
        {
          label: 'HẠN NỘP BÁO CÁO',
          field: 'dateExpired',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'submitReportStatusString',
          sortable: false,
        },
        {
          label: 'Năm',
          field: 'year',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'actionFunction',
          sortable: false,
          width: '175px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
      ],
      urlQuery: {
        expireReport: '',
        statusReport: '',
        bussinessId: '',
        year: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      urlQueryExcel: {
        expireReport: '',
        statusReport: '',
        bussinessId: '',
        year: null,
      },
      currentPage: 0,
      showBtnMultiDelete: false,
      userId: '',
      arrayExcel: null,
      totalRecord: 0,
      modalSendNotifi: 'comfirm-send',
      dataYear: [],
      sendIds: [],
      expireReport: [
        {
          vi: 'Đúng hạn',
          en: 'OnTime',
        },
        {
          vi: 'Quá hạn',
          en: 'OutOfDate',
        },
      ],
      statusReport: [
        {
          vi: 'Chưa tạo',
          en: 'None',
        },
        {
          vi: 'Chưa gửi duyệt',
          en: 'NotSend',
        },
        {
          vi: 'Bị trả lại/ Thu hồi',
          en: 'Reject',
        },
      ],
      dataTypeBussiness: [],
    }
  },
  computed: {
    ...mapGetters('performTraining', ['dataAllBusiness']),
    ...mapGetters('fiterYear', ['year', 'yearList']),
    statusVariant() {
      const statusColor = {
        // Chưa kích hoạt
        Send: 'warning',
        // Kích hoạt
        Approve: 'primary',
      }
      return status => statusColor[status]
    },
    isAllBussiness() {
      return this.sendIds.length === 0
    },
  },
  mounted() {
    this.fetchDataComboboxAllBusiness()
  },
  created() {
    this.dataYear = this.yearList
    this.fetchDataSNotReport(this.urlQuery)
    this.getDataTypeOfBussiness()
  },
  methods: {
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),

    ...mapActions('fiterYear', ['getYear']),
    // Show modal thu hồi
    showModalSendNotifi() {
      this.$bvModal.show(this.modalSendNotifi)
    },

    async getDataTypeOfBussiness() {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSSINESS_TYPE)
      this.dataTypeBussiness = data?.data?.pageLists
    },

    // Fetch data
    async fetchDataSNotReport(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_NOTSUBMIT_REPORT, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataSNotReport(this.urlQuery)
    },

    filterTypeBussiness(val) {
      if (val != null) {
        this.urlQueryExcel.bussinessTypeId = val
        this.urlQuery.bussinessTypeId = val
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      } else {
        this.urlQueryExcel.bussinessTypeId = ''
        this.urlQuery.bussinessTypeId = ''
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      }
    },

    // Lọc theo doanh nghiệp
    filterBussiness(val) {
      if (val != null) {
        this.urlQuery.bussinessId = val
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      } else {
        this.urlQuery.bussinessId = ''
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      }
    },

    // Lọc theo năm
    filterYear(val) {
      if (val != null) {
        this.urlQuery.year = val
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      } else {
        this.urlQuery.year = ''
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      }
    },

    // Tìm kiếm theo keyword
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchDataSNotReport(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchDataSNotReport(this.urlQuery)
      }
    },

    // Lọc theo trạng thái
    filterExpire(val) {
      if (val != null) {
        this.urlQueryExcel.expireReport = val
        this.urlQuery.expireReport = val
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      } else {
        this.urlQueryExcel.expireReport = ''
        this.urlQuery.expireReport = ''
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      }
    },
    filterStatus(val) {
      if (val != null) {
        this.urlQueryExcel.statusReport = val
        this.urlQuery.statusReport = val
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      } else {
        this.urlQueryExcel.statusReport = ''
        this.urlQuery.statusReport = ''
        this.urlQuery.pageNumber = 1
        this.fetchDataSNotReport(this.urlQuery)
      }
    },

    // Mở modal gửi thông báo
    openModalSend(id) {
      this.sendIds = [id]
      this.$bvModal.show(this.modalSendNotifi)
    },

    // gửi nhiều thông báo
    SendNotifications() {
      this.sendIds = this.$refs['user-table'].selectedRows.map(({ bussinessId }) => bussinessId)
      const sendIdsSet = new Set(this.sendIds)
      this.sendIds = [...sendIdsSet]
      this.$bvModal.show(this.modalSendNotifi)
    },
    // gửi thông báo đến tất cả donah nghiệp
    SendNotificationsAll() {
      this.sendIds = []
      this.$bvModal.show(this.modalSendNotifi)
    },

    // gửi thông báo
    async handleSendMessage(val) {
      const model = {
        ids: this.sendIds,
        message: val,
        year: this.isAllBussiness ? this.urlQuery.year : null,
        isAllBussiness: this.isAllBussiness,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.SEND_MESSAGE, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi thông báo thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalSendNotifi)
        this.fetchDataSNotReport(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi thông báo không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Tải file excel
    downloadExportFile() {
      axiosApiInstance({
        url: 'SituationReport/export-excel-report-not-send', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: this.urlQueryExcel,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'DoanhNghiepChuaNopBaoCao.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#unAssignedItem {
  .edit-report {
    cursor: pointer;
  }

  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .actiontd{
    text-align: center;
  }
  .actionth{
    text-align: center;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }
}
</style>
