<template>
  <b-modal
    :id="id"
    size="sm"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div class="modal-report">
      <validation-observer ref="rule">
        <b-form class="content-form">

          <b-form-group>
            <label for="InputHelp">Số hiệu văn bản<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="code"
              rules="required"
              :custom-messages="code"
            >
              <b-form-input
                v-model="dataInput.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="InputHelp">Tên văn bản<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="name"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Tệp tin<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="fileName"
              rules="required"
              :custom-messages="fileName"
            >
              <div class="d-flex">
                <b-form-input
                  v-model="dataInput.fileName"
                  :disabled="true"
                />

                <input
                  ref="upload-file"
                  type="file"
                  class="d-none"
                  @change="importFile"
                />
                <b-button
                  variant="outline-primary"
                  class="btn-button-file ml-1 d-flex"
                  @click="uploadFile"
                >
                  <feather-icon
                    icon="UploadIcon"
                    class="text-primary"
                  />
                </b-button>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Trích yếu</label>
            <b-form-input
              v-model="dataInput.content"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      code: {
        required: 'Số hiệu văn bản là bắt buộc',
      },
      name: {
        required: 'Tên văn bản là bắt buộc',
      },
      fileName: {
        required: 'Tệp tin là bắt buộc',
      },
      dataInput: {
        code: '',
        name: '',
        content: '',
        fileName: null,
        modelFormData: null,
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          code: this.dataDetail.code,
          name: this.dataDetail.name,
          content: this.dataDetail.content,
          fileName: this.dataDetail.fileName,
        }
      }
    },
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    uploadFile() {
      this.$refs['upload-file'].click()
    },
    importFile(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },

    resetData() {
      this.dataInput = {
        Code: '',
        name: '',
        Content: '',
        fileName: null,
        modelFormData: null,
      }
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
